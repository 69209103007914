import React from "react";
import footerLogoImg from "../assets/img/footer-logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="fooMenu">
          <div className="row">
            <div className="col-sm-2 colSm1">
              <div className="fooLogo">
                <img src={footerLogoImg} alt="Footer Logo" />
              </div>
            </div>
            <div className="col-sm-4 colSm2">
              <div className="address">
                <h5>Address</h5>
                <p>102 Woodmont Blvd., Suite 610 Nashville, TN 37205</p>
              </div>
            </div>
            <div className="col-sm-4 colSm3">
              <div className="phone">
                <h5>Phone</h5>
                <p>
                  <a href="tel:6157334730">(615) 733 4730 </a><br />
                  <a href="tel:6157334737">(615) 733 4737 (Investor Relations)</a> 
                </p>
              </div>
            </div>
            <div className="col-sm-2 colSm4">
              <div className="social">
                <h5>Social</h5>
                <div className="linkedin">
                  <Link to="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="74"
                      height="20"
                      viewBox="0 0 74 20"
                      fill="none"
                    >
                      <path
                        d="M73.9999 9.80152C73.9999 12.4991 73.9999 15.1967 73.9999 17.8943C73.9999 18.806 73.4718 19.342 72.5502 19.344C67.1471 19.3459 61.744 19.3459 56.3409 19.344C55.4252 19.344 54.8833 18.8139 54.8833 17.9002C54.8794 12.4872 54.8794 7.07623 54.8833 1.66324C54.8833 0.804914 55.4173 0.263022 56.2796 0.263022C61.7163 0.257089 67.1511 0.259067 72.5878 0.263022C73.4619 0.263022 73.99 0.812825 73.992 1.70873C73.9959 4.40632 73.992 7.10392 73.992 9.80152H73.9999ZM71.1777 16.5297C71.1856 16.4288 71.1975 16.3497 71.1975 16.2706C71.1975 14.4175 71.2113 12.5644 71.1876 10.7113C71.1816 10.2722 71.0946 9.81932 70.9641 9.39609C70.6338 8.32615 69.9673 7.54693 68.8084 7.38278C67.335 7.1771 65.8913 7.21467 64.6849 8.28461C64.6315 8.33208 64.5504 8.3479 64.4278 8.40328V7.452H62.0901V16.5238H64.8292C64.8292 15.5052 64.8055 14.5144 64.8371 13.5255C64.8609 12.7681 64.9083 12.0027 65.0329 11.2551C65.1852 10.3395 65.9803 9.75801 66.888 9.79954C67.6554 9.83514 68.1439 10.3711 68.2863 11.3362C68.3001 11.4351 68.308 11.534 68.312 11.6329C68.3476 13.0885 68.3832 14.5421 68.4168 15.9977C68.4208 16.1717 68.4168 16.3477 68.4168 16.5297H71.1737H71.1777ZM60.415 16.5416V7.45002H57.7115V16.5416H60.415ZM57.2665 4.41226C57.2685 5.52571 57.9903 6.24955 59.0939 6.24559C60.0669 6.24164 60.8916 5.44462 60.8402 4.4795C60.7789 3.35418 60.15 2.63825 59.0563 2.64221C58.0042 2.64616 57.2625 3.38385 57.2665 4.41226Z"
                        fill="white"
                      />
                      <path
                        d="M49.3003 8.25695V3.07733H52.0731V16.5614C51.3394 16.5614 50.6155 16.5732 49.8937 16.5475C49.7987 16.5436 49.6563 16.3517 49.6346 16.2291C49.593 15.9957 49.6227 15.7505 49.6227 15.4123C49.3597 15.5883 49.1421 15.7189 48.9424 15.8711C47.5441 16.947 46.045 16.8719 44.5321 16.1994C43.0508 15.5409 42.3784 14.3008 42.2498 12.7384C42.1153 11.0831 42.4733 9.57609 43.6955 8.36771C45.0245 7.05253 47.5204 6.90816 49.0116 8.04534C49.0769 8.09478 49.1441 8.14225 49.3003 8.25498V8.25695ZM46.9983 14.1921C48.3471 14.198 49.2845 13.3199 49.2905 12.0462C49.2964 10.7805 48.3075 9.81341 46.9983 9.8055C45.7029 9.79759 44.9454 10.5808 44.9395 11.9414C44.9336 13.3772 45.6732 14.1861 46.9963 14.1921H46.9983Z"
                        fill="white"
                      />
                      <path
                        d="M32.753 16.5633C31.6158 16.5633 30.623 16.5791 29.6302 16.5475C29.4601 16.5416 29.2505 16.3636 29.1357 16.2073C28.3091 15.0899 27.5041 13.9567 26.6913 12.8294C26.5865 12.685 26.4757 12.5426 26.2621 12.418V16.5396H23.4795V3.07928H26.2186V10.8873C26.4302 10.6974 26.5529 10.6064 26.6498 10.4957C27.3756 9.6789 28.1113 8.87002 28.8094 8.03147C29.1654 7.60231 29.555 7.38278 30.1305 7.40651C31.0324 7.44409 31.9362 7.4164 32.9231 7.4164C32.8261 7.56671 32.7945 7.63988 32.7431 7.69328C31.6138 8.87991 30.4905 10.0744 29.3434 11.2452C29.1061 11.4865 29.0823 11.6507 29.2841 11.9137C30.3382 13.2942 31.3804 14.6845 32.4247 16.0728C32.5176 16.1974 32.5967 16.3319 32.749 16.5653L32.753 16.5633Z"
                        fill="white"
                      />
                      <path
                        d="M35.0412 12.9856C35.1025 13.7767 35.6959 14.37 36.5047 14.4709C37.4699 14.5896 38.3895 14.55 39.1114 13.7411C39.1964 13.6462 39.5287 13.6581 39.6671 13.7451C40.1418 14.0437 40.5788 14.4037 41.0377 14.728C41.3798 14.9693 41.3521 15.1769 41.0357 15.4182C39.762 16.3893 38.2946 16.6879 36.7361 16.6602C35.1065 16.6306 33.8427 15.9324 32.9686 14.554C31.0502 11.5241 32.7946 7.82579 36.3406 7.38871C38.0652 7.17512 39.6216 7.44211 40.743 8.92539C41.5538 9.99731 41.7615 11.2314 41.6587 12.5406C41.631 12.8986 41.4332 12.9935 41.093 12.9916C39.2538 12.9797 37.4165 12.9856 35.5772 12.9856H35.0412ZM35.0531 10.9881C36.2239 10.9881 37.3908 11 38.5556 10.9802C38.9136 10.9743 38.8483 10.6994 38.7752 10.468C38.5418 9.74021 37.7467 9.23589 36.9557 9.31105C35.9134 9.40993 35.148 10.0606 35.0551 10.9901L35.0531 10.9881Z"
                        fill="white"
                      />
                      <path
                        d="M16.271 16.5416H13.522V7.44015H15.8596V8.50218C15.9783 8.45471 16.0436 8.4468 16.0811 8.4112C17.3172 7.23644 18.7807 7.10591 20.3391 7.52123C21.1401 7.73483 21.7097 8.26683 21.949 9.06978C22.0993 9.57212 22.2298 10.1021 22.2397 10.6203C22.2733 12.5229 22.2536 14.4254 22.2536 16.33C22.2536 16.3932 22.2377 16.4545 22.2259 16.5396H19.461C19.461 16.3715 19.463 16.1974 19.461 16.0254C19.4333 14.3562 19.4017 12.685 19.374 11.0158C19.3661 10.5115 19.2099 10.0705 18.6996 9.91624C18.1261 9.74418 17.4734 9.61563 17.0186 10.114C16.716 10.4443 16.4351 10.9249 16.4075 11.354C16.3086 12.9698 16.3165 14.5915 16.2848 16.2133C16.2848 16.3102 16.2749 16.4071 16.269 16.5455L16.271 16.5416Z"
                        fill="white"
                      />
                      <path
                        d="M2.74111 13.7846H8.30243V16.5238H0V3.07928H2.74111V13.7846Z"
                        fill="white"
                      />
                      <path
                        d="M12.2736 16.5435H9.54834V7.45001H12.2736V16.5435Z"
                        fill="white"
                      />
                      <path
                        d="M10.9406 2.67386C11.8444 2.68572 12.6829 3.53021 12.6909 4.43995C12.6988 5.35959 11.8266 6.21396 10.8951 6.20012C9.95371 6.18429 9.15867 5.37937 9.16065 4.44391C9.1646 3.48076 9.98733 2.66397 10.9386 2.67584L10.9406 2.67386Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="visit">
                  <Link to="https://www.harrow.com" target="_blank">Visit Harrow site</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="row">
            <div className="col-sm-5">
              <div className="cp-txt">
                <p>© 2025 Harrow, Inc.</p>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="pvc-ul">
                <ul>
                  <li>
                    <Link to="https://www.harrow.com/privacy.html" target="_blank">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="https://www.harrow.com/cookies.html" target="_blank">Cookie Notice</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
